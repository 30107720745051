const web3 = new Web3(Web3.givenProvider);

const form = document.querySelector(".donation");

const send = async function (amount) {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const wei = web3.utils.toWei(amount, "ether");

  if (accounts.length > 0) {
    window.ethereum.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: accounts[0],
          to: "0xD512872bB6448aCF6926C6C503d0Ceb8002C0557",
          value: web3.utils.toHex(wei),
        },
      ],
    });
  }
};

form.addEventListener("submit", function (event) {
  event.preventDefault();

  if (window.ethereum) {
    const input = form.querySelector(".donation__input");
    send(input.value);
  } else {
    alert("Please Install MetaMask on your browser, donations don't work on mobile :) ");
  }
});
